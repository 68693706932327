@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap');

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/Proxima Nova Thin.otf') format('opentype');
  font-weight: 100; /* Thin */
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Regular.otf') format('opentype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/Proxima Nova Semibold.ttf') format('truetype');
  font-weight: 600; /* Semi Bold */
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/Proxima Nova Bold.otf') format('opentype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/Proxima Nova Extrabold.otf') format('opentype');
  font-weight: 800; /* ExtraBold */
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/Proxima Nova Black.otf') format('opentype');
  font-weight: 900; /* Black */
  font-style: normal;
}



body {
  font-family: 'Proxima Nova', sans-serif;
}

.profile-image {
  transition: opacity 0.25s ease-in-out;
}


.HomepageContainer {
  // transition: all .5s;
  // background: linear-gradient(90deg, #f2f2f271 10%, #f2f2f2 70%);
  //white background
  background: #f7f8fd;
  background-repeat: no-repeat;

  //set svg background photo from assets folder bg.svg
  // background-image: url('./assets/bg.svg');
  //background-size: 100%;
  //set backgorund height to fit the screen 
  // height: 100vh;
  width: 100%;
  // position: absolute;
  background-size: cover;
  padding-top: 40px;
}

.bg-gradient {
  -webkit-animation: bg 8s;
  animation: bg 8s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: #0d1117;
  background-image: radial-gradient(circle farthest-corner at -25% -50%, #0d1117 36%, rgba(13, 17, 23, 0) 48%), radial-gradient(circle farthest-corner at -25% 150%, #0d1117 45%, rgba(13, 17, 23, 0) 89%), radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0) 55%, rgba(13, 17, 23, .28) 65%, rgba(13, 17, 23, 0) 68%), radial-gradient(circle farthest-corner at -33% -75%, #5d00ff 48%, rgba(131, 5, 49, 0) 56%), radial-gradient(circle farthest-side at 0 -50%, rgba(13, 17, 23, 0) 64%, rgba(1, 2, 36, .4) 69%, rgba(13, 17, 23, 0) 81%), radial-gradient(circle farthest-corner at 0 -50%, rgba(13, 17, 23, 0) 33%, #06015f 51%, rgba(13, 17, 23, 0) 72%);
  background-image: radial-gradient(circle farthest-corner at -25% -50%, #0d1117 36%, rgba(13, 17, 23, 0) 48%), radial-gradient(circle farthest-corner at -25% 150%, #0d1117 45%, rgba(13, 17, 23, 0) 89%), radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0) 55%, rgba(13, 17, 23, .28) 65%, rgba(13, 17, 23, 0) 68%), radial-gradient(circle farthest-corner at -33% -75%, #1f00cc 48%, rgba(131, 5, 49, 0) 56%), radial-gradient(circle farthest-side at 0 -50%, rgba(13, 17, 23, 0) 64%, rgba(1, 2, 36, .4) 69%, rgba(13, 17, 23, 0) 81%), radial-gradient(circle farthest-corner at 0 -50%, rgba(13, 17, 23, 0) 33%, #010c5f 51%, rgba(13, 17, 23, 0) 72%);
  background-size: 110vw 150vh;
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  padding-bottom: 250px;
  transition: all .5s ease-in-out;
}


.footer {
  box-shadow: 0 3px 4px 0 transparent;
  padding: 60px 0;
  background: #fff;
}

.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}


.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}


.logo {
  max-width: 200px;
}



.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.footer h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
}


.footer h6 {
  margin-bottom: 10px;
  font-weight: 16px;
}



.email-btn {
  margin: 10px 0;
  padding: 8px 20px;
  font-size: 15px;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  display: flex;
  background: #fff;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  text-decoration: none !important;
}


.to-black {
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
}

.from-gray-900 {
  --tw-gradient-from: #111827 var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(17, 24, 39, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}


.glasscard {
  /* From https://css.glass */
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.995), rgba(255, 255, 255, 0.696), rgba(255, 255, 255, 0.741));
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  //backdrop-filter: blur(7px);
  //-webkit-backdrop-filter: blur(7px);
  border: 1px solid #ffffff52;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  margin: 13px;
  width: 70%;
  border-radius: 5px;

  border-radius: 6px;
}

.custom-hover:hover {
  background-position: right 8px center;
  /* Adjust the value to control the movement */
}


.gradient-text-indigo-pink {
  background: linear-gradient(to right, #0066ff, rgb(154, 0, 116));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.gradient-text-green-blue {
  background: linear-gradient(to right, green, blue);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}


.download-btn {
  transition: transform 0.3s ease-in-out;
  /* Other styles */
}

/* Hover effect */
.download-btn:hover {
  transform: scale(1.05);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Responsive grid */
  grid-gap: 10px;
  align-items: center;
  justify-items: center;
  width: 90%;
  /* Corrected width property */
  max-width: 1000px;
  margin: auto;
}

.image-grid img {
  width: 60%;
  /* Adjust image width */
  height: auto;
  object-fit: cover;
}

/* Media Queries for Responsive Adjustments */
@media (max-width: 600px) {
  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    /* Smaller columns for smaller screens */
  }
}

@media (max-width: 400px) {
  .image-grid {
    grid-template-columns: 0.5fr 0.5f;
    /* Single column layout for very small screens */
  }
}